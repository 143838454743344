<template>
   <div class="navBox">
      <router-link to="./index"><van-button>用户列表</van-button></router-link>
      <router-link to="./crossChains"><van-button>跨链申请</van-button></router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.navBox {
  padding: 20px 30px;
  display: flex;
  a{
    margin-right: 10px;
  }
}
</style>