<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="text-align: right; padding: 30px">
        <van-button type="error" @click="showDeleteModal=true">删除用户</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{userList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>用户名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userList" :key="item._id">
              <td>{{ item._id }} </td>
              
              <td>
                <van-button @click="adminLogin(item._id)">登录</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <van-popup v-model="showDeleteModal">
      <div style="width: 600px;padding: 30px; text-align: center">
        <van-cell-group>
        <van-field label="batch" v-model="deleteForm.username"></van-field>
        <van-button :loading="deleteLoading" block type="primary" @click="deleteUser">提交</van-button>
        </van-cell-group>
      </div>
    </van-popup>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import jwt from 'jsonwebtoken'
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      userList: [],
      showDeleteModal: false,
      deleteLoading: false,
      deleteForm: {

      }
    };
  },

  mounted() {
    this.getUserList();
  },
  methods: {
    deleteUser() {
      this.deleteLoading = true
      return this.$fetch({
        url: "/admin/deleteUser",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        method: 'POST',
        data: {
          username: this.deleteForm.username
        }
      }).then(() => {
        this.showDeleteModal = false;
        this.getUserList()
      }).finally(() => {
        this.deleteLoading = false;
      })
    },
    getUserList() {
      return this.$fetch({
        url: "/admin/userList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.userList = res.data;
      })
    },
    adminLogin(username) {
      return this.$fetch({
        url: "/admin/adminLogin",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          username
        }
      }).then((res) => {
        let token = res.data;
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('tokenData', JSON.stringify(tokenData))
        window.location.href = '/user'
      })
    }
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>